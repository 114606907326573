import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/Dashboard";
import Team from "./scenes/Team";
import SpectrumQuickView from "./scenes/SpectrumQuickView";
import SpectrumPartnerPortfolio from "./scenes/SpectrumPartnerPortfolio";
import TeamQuickView from "./scenes/TeamQuickView";
import TeamExpView from "./scenes/TeamExpandedView";

import DirectTVQuickView from "./scenes/DirectTvQuickView";
import ATTQuickView from "./scenes/ATTQuickView";
import ReportsLanding from "./scenes/Reporting/ReportsLanding";
import SpectReportsLanding from "./scenes/Reporting/SpectReportsLanding";
import SpectCREntered from "./scenes/Reporting/SpectCREntered";
import SpectConnectedOrders from "./scenes/Reporting/SpectConnectedOrders";
import SpectCanceledOrders from "./scenes/Reporting/SpectCanceledOrders";
import SpectChargebacks from "./scenes/Reporting/SpectChargebacks";
import SpectPendingOrders from "./scenes/Reporting/SpectPendingOrders";

import Invoices from "./scenes/examples/invoices";
import Contacts from "./scenes/examples/contacts";
import Bar from "./scenes/examples/bar";
import Form from "./scenes/examples/form";
import Line from "./scenes/examples/line";
import Pie from "./scenes/examples/pie";
import FAQ from "./scenes/examples/faq";
import Geography from "./scenes/examples/geography";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Calendar from "./scenes/examples/calendar/calendar";
import Landing from "./scenes/SignIn";
import Signup from "./scenes/Signup";
import Account from "./scenes/Account";
import { AuthContextProvider, UserAuth } from "./context/AuthContext.js";
import ProtectedRoute from "./scenes/protectedRoute";
import IFrame from "./scenes/forms/SalesOrderPortal";
import Portals from "./scenes/Portals";
import PRSReports from "./scenes/PRS-Reports";
import Training from "./scenes/Training";

import PartnerRegisterSale from "./scenes/forms/PartnerRegisterSale";
import MobileOrderEntry from "./scenes/forms/MobileOrderEntry";
import AgentRegistration from "./scenes/forms/AgentRegistration";
import SubmitAppeal from "./scenes/forms/SubmitAppeal";
import AdApproval from "./scenes/forms/AdApproval";
import SpectrumMDP from "./scenes/portals/SpectrumMDP";
import Numbers from "./scenes/Numbers";
import News from "./scenes/News";
import NewsViewAll from "./scenes/NewsViewAll";
import CreatePost from "./scenes/blog/CreatePost";
import EventForm from "./scenes/forms/EventForm";
import ShowPost from "./scenes/blog/ShowPost";
import EditPost from "./scenes/blog/EditPost";
import OnlineMarketing from "./scenes/OnlineMarketing";
import firebase from "firebase/app";
import { auth } from "./firebase";

import MarketingDnD from "./scenes/Trainings/MarketingDnD";
import MarketingDnDQuiz from "./scenes/Trainings/MarketingDnDQuiz";
import InternetSpectrumOne from "./scenes/Trainings/InternetSpectrumOne";
import InternetSpectrumOneQuiz from "./scenes/Trainings/InternetSpectrumOneQuiz";
import Sales101 from "./scenes/Trainings/Sales101";
import Sales101Quiz from "./scenes/Trainings/Sales101Quiz";
import SpectrumMobile from "./scenes/Trainings/SpectrumMobile";
import SpectrumMobileQuiz from "./scenes/Trainings/SpectrumMobileQuiz";
import PortalTraining from "./scenes/Trainings/PortalTraining";
import PortalTrainingQuiz from "./scenes/Trainings/PortalTrainingQuiz";
import CallFlow from "./scenes/Trainings/CallFlow";
import DataUpload from "./scenes/DataUpload";
import ServiceProviders from "./scenes/forms/ServiceProviders.jsx";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CallFlowQuiz from "./scenes/Trainings/CallFlowQuiz.jsx";
import RolePlayTraining from "./scenes/Trainings/RolePlayTraining.jsx";
import AddCampaignForm from "./scenes/forms/CampaignInput.jsx";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const [Agent, setAgent] = useState();
  const [Hierarchy, setHierarchy] = useState();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        console.log("User Email:", currentUser.email);
        let payload = { email: currentUser.email };
        const getAgentData = async () => {
          try {
            const response = await fetch(
              "https://us-central1-argon-fx-383518.cloudfunctions.net/getUserAgent", // Replace with your Cloud Function URL
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
              }
            )
              .then((response) => response.json())
              .then((data) => {
                // Do something with the data, e.g. update component state
                if (data != "" && data[0].Email == currentUser.email) {
                  if (data[0].Status === "Disabled") {
                    // User is disabled, sign them out
                    auth
                      .signOut()
                      .then(() => {
                        alert(
                          "User signed out due to disabled status, Please contact your Account Manager for more information."
                        );
                      })
                      .catch((error) => {
                        console.error("Error signing out user:", error);
                      });
                  } else {
                    setAgent(data[0]);
                  }
                } else {
                  alert(
                    "An Error has occured please reach out to Vomtel for help resolving this issue."
                  );
                }
              })
              .catch((error) => {
                // Handle error
                console.error(error);
              });
          } catch (e) {
            console.error(e.message);
          }
        };
        getAgentData();
        // getHierarchyData();
      } else {
        console.log("User not logged in");
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <ToastContainer
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "fit-content",
          }}
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <CssBaseline />
        <div className="app">
          <Sidebar isSidebar={isSidebar} agent={Agent} />
          <main className="content">
            <Topbar setIsSidebar={setIsSidebar} />
            <AuthContextProvider>
              <Routes>
                <Route path="/signin" element={<Landing />} />
                <Route path="/signup" element={<Signup />} />
                <Route
                  path="/account"
                  element={
                    <ProtectedRoute>
                      <Account />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/"
                  element={
                    <ProtectedRoute>
                      <Dashboard agent={Agent} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/portals"
                  element={
                    <ProtectedRoute>
                      <Portals agent={Agent} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/iframe"
                  element={
                    <ProtectedRoute>
                      <IFrame agent={Agent} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/Campaigns"
                  element={
                    <ProtectedRoute>
                      <AddCampaignForm agent={Agent} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/ServiceProviders"
                  element={
                    <ProtectedRoute>
                      <ServiceProviders agent={Agent} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/training"
                  element={
                    <ProtectedRoute>
                      <Training agent={Agent} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/MarketingDnD"
                  element={
                    <ProtectedRoute>
                      <MarketingDnD />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/MarketingDnDQuiz"
                  element={
                    <ProtectedRoute>
                      <MarketingDnDQuiz agent={Agent} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/InternetSpectrumOne"
                  element={
                    <ProtectedRoute>
                      <InternetSpectrumOne />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/InternetSpectrumOneQuiz"
                  element={
                    <ProtectedRoute>
                      <InternetSpectrumOneQuiz agent={Agent} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/Sales101"
                  element={
                    <ProtectedRoute>
                      <Sales101 />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/Sales101Quiz"
                  element={
                    <ProtectedRoute>
                      <Sales101Quiz agent={Agent} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/PortalTraining"
                  element={
                    <ProtectedRoute>
                      <PortalTraining />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/PortalTrainingQuiz"
                  element={
                    <ProtectedRoute>
                      <PortalTrainingQuiz agent={Agent} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/CallFlow"
                  element={
                    <ProtectedRoute>
                      <CallFlow />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/CallFlowQuiz"
                  element={
                    <ProtectedRoute>
                      <CallFlowQuiz agent={Agent} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/RolePlayTraining"
                  element={
                    <ProtectedRoute>
                      <RolePlayTraining />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/SpectrumMobile"
                  element={
                    <ProtectedRoute>
                      <SpectrumMobile />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/SpectrumMobileQuiz"
                  element={
                    <ProtectedRoute>
                      <SpectrumMobileQuiz agent={Agent} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/team"
                  element={
                    <ProtectedRoute>
                      <Team agent={Agent} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/spectrumQuickView"
                  element={
                    <ProtectedRoute>
                      <SpectrumQuickView agent={Agent} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/teamQuickView"
                  element={
                    <ProtectedRoute>
                      <TeamQuickView agent={Agent} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/teamExpView"
                  element={
                    <ProtectedRoute>
                      <TeamExpView agent={Agent} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/spectrumPartnerPortfolio"
                  element={
                    <ProtectedRoute>
                      <SpectrumPartnerPortfolio agent={Agent} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/directtvQuickView"
                  element={
                    <ProtectedRoute>
                      <DirectTVQuickView agent={Agent} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/attQuickView"
                  element={
                    <ProtectedRoute>
                      <ATTQuickView agent={Agent} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/numbers"
                  element={
                    <ProtectedRoute>
                      <Numbers agent={Agent} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/news"
                  element={
                    <ProtectedRoute>
                      <News agent={Agent} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/newsviewall"
                  element={
                    <ProtectedRoute>
                      <NewsViewAll agent={Agent} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/createPost"
                  element={
                    <ProtectedRoute>
                      <CreatePost />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/contacts"
                  element={
                    <ProtectedRoute>
                      <Contacts />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/invoices"
                  element={
                    <ProtectedRoute>
                      <Invoices />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/form"
                  element={
                    <ProtectedRoute>
                      <Form />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/partnerRegisterSale"
                  element={
                    <ProtectedRoute>
                      <PartnerRegisterSale agent={Agent} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/ReportsLanding"
                  element={
                    <ProtectedRoute>
                      <ReportsLanding agent={Agent} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/SpectrumReporting"
                  element={
                    <ProtectedRoute>
                      <SpectReportsLanding agent={Agent} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/SpectrumCREnteredOrders"
                  element={
                    <ProtectedRoute>
                      <SpectCREntered agent={Agent} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/SpectrumChargebacks"
                  element={
                    <ProtectedRoute>
                      <SpectChargebacks agent={Agent} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/SpectrumPendingOrders"
                  element={
                    <ProtectedRoute>
                      <SpectPendingOrders agent={Agent} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/SpectrumConnectedOrders"
                  element={
                    <ProtectedRoute>
                      <SpectConnectedOrders agent={Agent} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/SpectrumCanceledOrders"
                  element={
                    <ProtectedRoute>
                      <SpectCanceledOrders agent={Agent} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/dataUpload"
                  element={
                    <ProtectedRoute>
                      <DataUpload />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/PRS-Reports"
                  element={
                    <ProtectedRoute>
                      <PRSReports />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/mobileOrderEntry"
                  element={
                    <ProtectedRoute>
                      <MobileOrderEntry />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/agentRegistration"
                  element={
                    <ProtectedRoute>
                      <AgentRegistration agent={Agent} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/submitAppeal"
                  element={
                    <ProtectedRoute>
                      <SubmitAppeal />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/adApproval"
                  element={
                    <ProtectedRoute>
                      <AdApproval />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/SpectrumMDP"
                  element={
                    <ProtectedRoute>
                      <SpectrumMDP />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/eventForm"
                  element={
                    <ProtectedRoute>
                      <EventForm />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/showPost"
                  element={
                    <ProtectedRoute>
                      <ShowPost agent={Agent} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/editPost"
                  element={
                    <ProtectedRoute>
                      <EditPost />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/onlineMarketing"
                  element={
                    <ProtectedRoute>
                      <OnlineMarketing />
                    </ProtectedRoute>
                  }
                />
                <Route path="/bar" element={<Bar />} />
                <Route path="/pie" element={<Pie />} />
                <Route path="/line" element={<Line />} />
                <Route path="/faq" element={<FAQ agent={Agent} />} />
                <Route
                  path="/calendar"
                  element={
                    <ProtectedRoute>
                      <Calendar />
                    </ProtectedRoute>
                  }
                />
                <Route path="/geography" element={<Geography />} />
              </Routes>
            </AuthContextProvider>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
